<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="about_client" service="credit" backlink="/credit/members" />
      <div class="content illustration illustration_form">
        <div class="content__container">
          <Clients service="credit" :clients="clients" nextlink="/credit/have-credit" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import Clients from '@/components/service/Clients.vue'

export default {
  name: 'Credit-client',
  props: { clients: { type: Number } },
  components: { Backlink, Clients }
}
</script>
